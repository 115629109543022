@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap');

h1.mainHeader {
  font-family: 'Cinzel Decorative', cursive;
  /* font-size: 7vw; */
  color: #206EA6;
  text-shadow: 0 0 2px white;
  border-bottom: 2px solid #206EA6;
}

.hugeLogo {
  font-size: 30vw;
  text-shadow: 0 0 15px black;
  color: rgb(255, 255, 255);
  opacity: 0.08;
  transform: rotate(5deg);
}


@layer base {
    body {
      @apply font-[Raleway]
    }

    li {
      @apply px-4;
      
    }
}

